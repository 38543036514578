import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import styled from "styled-components"
import SEO from "../components/seo"
import { PageProps } from "gatsby"
import useMedia from "../components/customHooks/useMedia"

const IndexPage: React.FC<PageProps> = ({ data }) => {
  // const isMobile = useMedia("(max-width: 767px)")
  return (
    <Layout>
      <SEO
        lang="ja"
        title="フニオチル - Home"
        description="孤独に「もの」と向き合う時間。ウィズコロナのジャグリング入門"
        picUrl={"https://funiochiru.juggling-pintcle.com/static/c265d63bc6131b90f7c6958047e3f6ee/4dc05/title.jpg"}
      />
      <Article>

        <TitleWrap>
          <TitleMain>ジャグリング公演『フニオチル』</TitleMain>
          <TitleDesc>孤独に「もの」と向き合う時間。ウィズコロナのジャグリング入門</TitleDesc>
        </TitleWrap>
        <TopImage
          filename={`title.jpg`}
        />

        <PhilosophyDesc>
          <p>「もの」と向き合う時、人は孤独である。人は孤独でなければ、「もの」と向き合うことができない。</p>
          <p>新型感染症の流行によって、集まることが難しくなった。その中でピントクルの活動も変容を余儀なくされ、2020年春に企画していた公演は無観客で行われた。観客のいない本番は、孤独に「もの」と向き合うことの意味を示しているように思われた。それは理想的なジャグリングを共有する場ではないか。 本企画は、あえて積極的に無観客公演を行うものである。</p>
          <p>本公演は、オムニバス公演である。「ジャグリング入門」をテーマとして、日本のジャグリングの最も良質な部分を象徴する三組を集めた。それぞれのパフォーマンスを通じて、ジャグリング＝「身体と道具をつなぐもの」の深みを感じてもらいたい。</p>
        </PhilosophyDesc>
        <Section>
          <H2>出演</H2>
          <div>
            大橋家<br />
            KOMEI<br />
            染谷樹<br />
          </div>
        </Section>
        <Section>
          <H2>タイムスケジュール</H2>
          <div>
            2/13(土) 、14(日)<br />
            映像配信 14:00-17:00<br />
            <br />
            14:00- 大橋家<br />
            15:00- 染谷樹<br />
            16:00- KOMEI<br />
            <Alert>※各演者1h(パフォーマンス、トークなど含む)</Alert>
          </div>
        </Section>
        <Section>
          <H2>配信</H2>
          <div>
            ・2/13(土) 14:00-17:00
          </div>
          <VideoWrap><iframe loading="lazy" width="560" height="315" src="https://www.youtube.com/embed/D6ElAMnB18s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></VideoWrap>
          <div>・2/14(日) 14:00-17:00</div>
          <VideoWrap><iframe loading="lazy" width="560" height="315" src="https://www.youtube.com/embed/sZlpVLisxrQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></VideoWrap>
        </Section>
        <Section>
          <H2>会場</H2>
          <div>
            若葉町ウォーフ<br />
            横浜市中区若葉町3-47-1<br />
            <Alert>
              ※ 2020/2/10更新<br />
              会場は <A href="http://hanareproject.net/">Social Kicthen</A> に変更になりました。
            </Alert>
          </div>
        </Section>
        <Section>
          <H2>料金</H2>
          <div>
            無料
            <br />
            <Alert>※YouTube Live による映像配信となります</Alert>
          </div>
        </Section>
        <Author>
          主催: Juggling Unit ピントクル<br />
          助成：文化庁　文化芸術活動の継続支援事業
        </Author>
        <Left>
          <Link to="/report/">観賞レポート</Link>
        </Left>
      </Article>
    </Layout>
  )
}

export default IndexPage

const TopImage = styled(Image)`
  display: block;
  margin: 0 auto 2.5rem;
  max-width: 540px;
  filter: brightness(1.02);
`;

const Article = styled.article`
  padding: 2rem 1rem 4rem;
  color: #212121;
`

const Section = styled.section`
  margin-bottom: 3rem;
  &:last-of-type {
    margin-bottom: 1rem;
  }
`;

const TitleWrap = styled.div`
  margin-bottom: 2.5rem;
  text-align: center;
`;

const TitleMain = styled.h1`
  margin-bottom: .5rem;
  font-size: 1.825rem;
  font-weight: 600;
  letter-spacing: 2.4px;
  /* タブレットも含む */
  @media screen and (max-width: 768px) {
   font-size: 1.25rem;
  }
`

const TitleDesc = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.8px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`
const H2 = styled.h2`
  margin-bottom:1rem;
  font-size: 1.125rem;
  line-height: 1.75;
  border-left: 8px solid #c0c1c2;
  padding-left: 10px;
  letter-spacing: 2px;
`;


const PhilosophyDesc = styled.div`
  margin-bottom: 3rem;
`

const Left = styled.div`
  text-align: left;
  text-decoration: underline;
  >a {
    display: inline-block;
    text-decoration: underline;
    &:before {
      content: "＞";
      display: inline-block;

    }
  }
`


const Alert = styled.p`
  display: block;
  font-size: 14px;
  margin-top: 8px;
`;

const VideoWrap = styled.div`
  margin: 1rem auto 2rem;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  overflow:auto;
  >iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    border:none;
    display:block;
  }
`;

const Author = styled.div`
  font-weight: bold;
  color: #797979;
  font-size: 14px;
  margin-bottom:2rem;
`;

const A = styled.a`
  text-decoration: underline;
`